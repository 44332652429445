function ret_mask_MagicN(subnet) {
    const cidr = {
        "32": "255.255.255.255", "31": "255.255.255.254", "30": "255.255.255.252", "29": "255.255.255.248",
        "28": "255.255.255.240", "27": "255.255.255.224", "26": "255.255.255.192", "25": "255.255.255.128",
        "24": "255.255.255.0", "23": "255.255.254.0", "22": "255.255.252.0", "21": "255.255.248.0",
        "20": "255.255.240.0", "19": "255.255.224.0", "18": "255.255.192.0", "17": "255.255.128.0",
        "16": "255.255.0.0", "15": "255.254.0.0", "14": "255.252.0.0", "13": "255.248.0.0", "12": "255.240.0.0",
        "11": "255.224.0.0", "10": "255.192.0.0", "9": "255.128.0.0", "8": "255.0.0.0", "7": "254.0.0.0",
        "6": "252.0.0.0", "5": "248.0.0.0", "4": "240.0.0.0", "3": "224.0.0.0", "2": "192.0.0.0", "1": "128.0.0.0"
    };

    return cidr[subnet] || null;
}

function subnet_result(ID, sub) {
    console.log(ID);
    console.log(sub);
    const network = ID.split(".");
    const mask = sub.split(".");
    let result_net = "";
    for (let i = 0; i < 4; i++) {
        result_net += (parseInt(network[i]) & parseInt(mask[i])) + ".";
    }
    return result_net.slice(0, -1);
}

function Total_Host(cidr) {
    const total_host = 2 ** (32 - parseInt(cidr));
    const total_usable_host = total_host - 2;
    return [total_host, total_usable_host];
}

function ret_FA_LA_BA(network, mask) {
    let broadcast_address = "";
    let broadcast_add = "";
    let wildcard_address = "";
    const network_parts = network.split('.').map(Number);
    const maskParts = mask.split('.').map(Number);

    const wildcard = maskParts.map(part => 255 - part);

    wildcard_address = wildcard.join('.');
    for (let i = 0; i < 4; i++) {
        broadcast_add += (parseInt(network_parts[i]) | parseInt(wildcard[i])) + ".";
    }
    broadcast_address += broadcast_add.slice(0, -1);

    const broadcast_parts = broadcast_address.split('.').map(Number);

    const first_address = network_parts.slice();
    first_address[3] += 1;
    const first_address_string = first_address.join('.');

    const last_address = broadcast_parts.slice();
    last_address[3] -= 1;
    const last_address_string = last_address.join('.');

    return [first_address_string, last_address_string, broadcast_address, wildcard_address];
}

function getbestMask(hosts) {
    console.log(hosts); 

    const host_mask_dict = {
        2: 30, 6: 29, 14: 28, 30: 27, 62: 26, 126: 25, 254: 24, 510: 23, 1022: 22, 2046: 21, 4094: 20, 8190: 19,
        16382: 18, 32766: 17, 65534: 16, 131070: 15, 262142: 14, 524286: 13, 1048574: 12, 2097150: 11, 4194302: 10,
        8388606: 9, 16777214: 8, 134217726: 7, 268435454: 4, 536870910: 3, 1073741822: 2, 2147483646: 1, 4294967294: 0
    };


    for (const key in host_mask_dict) {
        if (hosts <= key) {
            return host_mask_dict[key];
        }
    }
}

function increment_ip(ip_addr) {
    const parts = ip_addr.split('.').map(Number);
    for (let i = 3; i >= 0; i--) {
        if (parts[i] < 255) {
            parts[i]++;
            break;
        }
        parts[i] = 0;
    }
    return parts.join('.');
}
function calculate_network_info(ip_address, CIDR, num_groups, group_info) {
    const network_reg = new RegExp(
        `^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\\.` +
        `(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\\.` +
        `(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\\.` +
        `(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\\/([0-9]|[12][0-9]|3[0-2])$`
    );

    if (network_reg.test(`${ip_address}/${CIDR}`)) {
        const sub = ret_mask_MagicN(CIDR);
        const network_address = subnet_result(ip_address, sub);
        const [tl_host, tl_useable_host] = Total_Host(CIDR);
        const [first_address, last_address, broadcast_address, wildcard_address] = ret_FA_LA_BA(network_address, sub);

        const results = [{
            "IP_ADDRESS": ip_address,
            "SUBNET_MASK": sub,
            "NETWORK_ID": network_address,
            "FIRST_ADDRESS": first_address,
            "LAST_ADDRESS": last_address,
            "BROADCAST_ADDRESS": broadcast_address,
            "WILDCARD_ADDRESS": wildcard_address,
            "TOTAL_HOSTS": tl_host,
            "TOTAL_USABLE_HOSTS": tl_useable_host
        }];

        const sorted_group_info = Object.fromEntries(
            Object.entries(group_info).sort(([, a], [, b]) => b - a)
        );

        let next_address = network_address;
        for (const [key, value] of Object.entries(sorted_group_info)) {
            const best_sub = getbestMask(value);
            console.log(best_sub, "line 119********");
            const sub = ret_mask_MagicN(String(best_sub));
            console.log(sub, "line 120********");
            const network_address = subnet_result(next_address, sub);
            const [tl_host, tl_useable_host] = Total_Host(best_sub);
            const [first_address, last_address, broadcast_address, wildcard_address] = ret_FA_LA_BA(network_address, sub);

            results.push({
                "SUBNETTING_GROUP": key,
                "NETWORK_ID": network_address,
                "FIRST_ADDRESS": first_address,
                "LAST_ADDRESS": last_address,
                "BROADCAST_ADDRESS": broadcast_address,
                "SUBNET_MASK": sub,
                "WILDCARD_ADDRESS": wildcard_address,
                "TOTAL_HOSTS": tl_host,
                "TOTAL_USABLE_HOSTS": tl_useable_host
            });

            next_address = increment_ip(broadcast_address);
        }

        return results;
    } else {
        return {"error": "Invalid network ID or CIDR notation format. Please try again."};
    }
}

export default calculate_network_info;