import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportToExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    // Create a new workbook and a new worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [['Credits: Calculations by Subnet Spectra (Visit subnetspectra.com for more info)']], { origin: -1 });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a binary string from the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a Blob from the binary string and save it using file-saver
    const excelBlob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    saveAs(excelBlob, `${fileName}.xlsx`);
  };

  return (
    <button id="exportToExcelBtn"  onClick={exportToExcel}>Export to Excel</button>
  );
};

export default ExportToExcel;
