import React, { useState, useRef, useEffect } from "react";
import calculate_network_info from "../functions/functions";
import './Home.css';
import ExportToExcel from './ExportToExcel';

const Home = () => {
    // State variables for user inputs and results
    const [ipAddress, setIpAddress] = useState("");
    const [numGroups, setNumGroups] = useState(0);
    const [groupInfo, setGroupInfo] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    // For calculate button
    const [buttonVisible, setButtonVisible] = useState(false);
    const [exportHTMLVisible, setExportHTMLVisible] = useState(false);
    const containerTwoRef = useRef(null);
    const containerThreeRef = useRef(null);

    let state = {
        results: [],
        isVisible: false
    };

    useEffect(() => {
        const reloadInputRef = document.getElementById('reloadInputRef');
        if (reloadInputRef) {
            reloadInputRef.focus();
        } else {
            console.error('Element with ID reloadInputRef not found');
        }
    }, []);



    // To validate the syntax of the Network ID and the CIDR
    function validateIpSubnet(input) {
        const ipSubnetPattern = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\/([0-9]|[1-2][0-9]|3[0-2])$/;
        return ipSubnetPattern.test(input);
    }

    // To split Network ID and CIDR
    function splitIpAddressAndSubnet(input) {
        const [ip, subnetMask] = input.split('/');
        return { ip, subnetMask };
    }

    // To calculate the maximum number of subnets based on CIDR prefix
    function calculateSubnets(cidrPrefix) {
        let additionalBits = 32 - cidrPrefix;
        let numSubnets = Math.pow(2, additionalBits);
        numSubnets = numSubnets / 4;
        return numSubnets;
    }

    // to verify if the given cidr is in range of 0-32
    function verifyingSubnet(cidr) {
        if (isNaN(cidr) || cidr < 0 || cidr > 32) {
            return false;
        }
        return true;
    }

    // To print the final calculated result
    const printResult = (result) => {
        result = String(result);

        let data;
        try {
            data = JSON.parse(result);
        } catch (e) {
            document.getElementById('resultTable').innerText = 'Invalid data format';
            return;
        }

        let tableHTML = `
            <h3>Subnetting</h3>
            <div class="responsiveDiv">
               <div class="header">
                    <div class="cell">IP Address</div>
                    <div class="cell">Subnet Mask</div>
                    <div class="cell">Network ID</div>
                    <div class="cell">First Address</div>
                    <div class="cell">Last Address</div>
                    <div class="cell">Broadcast Address</div>
                    <div class="cell">Wildcard Address</div>
                    <div class="cell">Total Hosts</div>
                    <div class="cell">Total Usable Hosts</div>
                </div>
                <div class="row">
                    <div class="cell">${data[0].IP_ADDRESS || ''}</div>
                    <div class="cell">${data[0].SUBNET_MASK || ''}</div>
                    <div class="cell">${data[0].NETWORK_ID}</div>
                    <div class="cell">${data[0].FIRST_ADDRESS}</div>
                    <div class="cell">${data[0].LAST_ADDRESS}</div>
                    <div class="cell">${data[0].BROADCAST_ADDRESS}</div>
                    <div class="cell">${data[0].WILDCARD_ADDRESS}</div>
                    <div class="cell">${data[0].TOTAL_HOSTS}</div>
                    <div class="cell">${data[0].TOTAL_USABLE_HOSTS}</div>
                </div>
            </div>
            <h3 style="margin-top: 50px">VLSM</h3>
            <div class="responsiveDiv" style="color: white;">
                
        `;


        data.slice(1).forEach(subnet => {
            tableHTML += `
            <div class="responsiveDiv">
                <div class="header">
                    <div class="cell">Subnetting Group</div>
                    <div class="cell">Subnet Mask</div>
                    <div class="cell">Network ID</div>
                    <div class="cell">First Address</div>
                    <div class="cell">Last Address</div>
                    <div class="cell">Broadcast Address</div>
                    <div class="cell">Wildcard Address</div>
                    <div class="cell">Total Hosts</div>
                    <div class="cell">Total Usable Hosts</div>
                    <div class="cell">Total Unused Hosts</div>
                </div>
                <div class="row">
                    <div class="cell">${subnet.SUBNETTING_GROUP || ''}</div>
                    <div class="cell">${subnet.SUBNET_MASK || ''}</div>
                    <div class="cell">${subnet.NETWORK_ID}</div>
                    <div class="cell">${subnet.FIRST_ADDRESS}</div>
                    <div class="cell">${subnet.LAST_ADDRESS}</div>
                    <div class="cell">${subnet.BROADCAST_ADDRESS}</div>
                    <div class="cell">${subnet.WILDCARD_ADDRESS}</div>
                    <div class="cell">${subnet.TOTAL_HOSTS}</div>
                    <div class="cell">${subnet.TOTAL_USABLE_HOSTS}</div>
                    <div class="cell">${subnet.TOTAL_HOSTS - subnet.TOTAL_USABLE_HOSTS}</div>
                </div>
            </div>
            </div>
            `;
        });

        data.slice(1).forEach(subnet => {
            tableHTML += `</div>`;
        });

        document.getElementById('resultTable').innerHTML = tableHTML;
    };

    const verifySubnettingGroupsInput = (maxGroups) => {
        maxGroups = maxGroups * 4;
        const tableInputs = document.querySelectorAll('.tableInput');
        let groupSubnetTotal = 0; // Initialize the counter to 0

        for (let i = 0; i < tableInputs.length; i++) {
            const value = tableInputs[i].value.trim();

            if (value === "" || value === null) {
                alert("Please fill out all the details in the subnetting groups.");
                return false;
            }

            if (i % 2 !== 0) { // Check every second input starting from index 1
                const numberValue = Number(value); // Convert value to a number
                if (isNaN(numberValue)) {
                    alert("Please enter valid numbers in the subnetting groups.");
                    return false;
                }
                groupSubnetTotal += numberValue; // Add the numeric value to groupSubnetTotal
            }
        }

        if (groupSubnetTotal > maxGroups) {
            alert("The number of subnetting groups exceeds the maximum allowed.");
            return false;
        }

        return true;
    };



    // To calculate the final Subnetting and VLSM result
    const handleCalculate = () => {
        const { subnetMask } = splitIpAddressAndSubnet(ipAddress); // Seperating CIDR from Network ID
        let maxGroups = calculateSubnets(subnetMask); // Calculating max number of groups that can be made with the given CIDR
        //Verifying if the user entered the Network ID and the CIDR
        if (ipAddress === "") {
            alert('Please enter Network ID and CIDR');
        }
        //Verifying if the IP and CIDR syntax
        else if (!validateIpSubnet(ipAddress)) {
            alert("Please enter a valid Network ID and CIDR");
        }
        //Verifying if the subnet mask is in the range 0 - 32
        else if (!verifyingSubnet(subnetMask)) {
            alert("Please enter a valid CIDR number between 1 and 32.");
        }
        // Verifying the given number of groups with the max groups possible with the given subnet
        else if (numGroups < 1 || numGroups > maxGroups) {
            alert(`Min groups possible: 1 | Max groups possible: ${calculateSubnets(subnetMask)}`);
        }
        else if (!verifySubnettingGroupsInput(maxGroups)) {
            return false;
        }
        else {
            const { ip, subnetMask } = splitIpAddressAndSubnet(ipAddress);

            const groupObject = groupInfo.reduce((acc, current) => {
                acc[current.name] = current.hosts;
                return acc;
            }, {});

            console.log(ip, subnetMask, numGroups, groupObject);

            const results = calculate_network_info(ip, subnetMask, numGroups, groupObject);
            console.log(results);
            printResult(JSON.stringify(results));
            console.log(error);
            console.log(data);
            console.log(state);

            let formattedResults = results.map(result => ({
                'IP Address': result.IP_ADDRESS,
                'Subnet Mask': result.SUBNET_MASK,
                'Network ID': result.NETWORK_ID,
                'First Address': result.FIRST_ADDRESS,
                'Last Address': result.LAST_ADDRESS,
                'Broadcast Address': result.BROADCAST_ADDRESS,
                'Wildcard Address': result.WILDCARD_ADDRESS,
                'Total Hosts': result.TOTAL_HOSTS,
                'Total Usable Hosts': result.TOTAL_USABLE_HOSTS,
                'Subnetting Group': result.SUBNETTING_GROUP || ''
            }));
            

            //To continue with the result
            setData(formattedResults);
            setTimeout(() => {
                containerThreeRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
            setExportHTMLVisible(true);
            setError(null);
        }
    };

    // Function to add or update group information
    const handleGroupChange = (index, field, value) => {
        const updatedGroups = [...groupInfo];
        updatedGroups[index] = { ...updatedGroups[index], [field]: value };
        setGroupInfo(updatedGroups);
    };

    // To add number of groups based on given by the user after verifying
    const addGroup = (number) => {
        const { subnetMask } = splitIpAddressAndSubnet(ipAddress); // Seperating CIDR from Network ID
        let maxGroups = calculateSubnets(subnetMask); // Calculating max number of groups that can be made with the given CIDR
        //Verifying if the user entered the Network ID and the CIDR
        if (ipAddress === "") {
            alert('Please enter Network ID and CIDR');
        }
        //Verifying if the IP and CIDR syntax
        else if (!validateIpSubnet(ipAddress)) {
            alert("Please enter a valid Network ID and CIDR");
        }
        //Verifying if the subnet mask is in the range 0 - 32
        else if (!verifyingSubnet(subnetMask)) {
            alert("Please enter a valid CIDR number between 1 and 32.");
        }
        // Verifying the given number of groups with the max groups possible with the given subnet
        else if (number < 1 || number > maxGroups) {
            alert(`Min groups possible: 1 | Max groups possible: ${calculateSubnets(subnetMask)}`);
        }
        else {
            const newGroups = [groupInfo];
            for (let i = 1; i < number; i++) {
                newGroups.push({ name: "", hosts: "" });
            }

            setButtonVisible(true);
            setGroupInfo(newGroups);
            setTimeout(() => {
                containerTwoRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };


    // To remove all the groups together
    const removeAllGroups = () => {
        // if (groupInfo.length === 0) {
        //     alert('The subnetting group is already empty.');
        //     return;
        // }
        setButtonVisible(false);
        setGroupInfo([]);
        setNumGroups(0);
    };


    // To remove the specific groups
    const removeGroup = (index) => {
        const updatedGroups = [...groupInfo];
        updatedGroups.splice(index, 1);
        setGroupInfo(updatedGroups);
        setNumGroups(numGroups - 1);

        if (updatedGroups.length === 0) {
            setButtonVisible(false);
        }
    };

    const clearAll = () => {
        setIpAddress("");
        setNumGroups(0);
        removeAllGroups();
        document.getElementById('resultTable').innerHTML = "";
        setExportHTMLVisible(false);
        const reloadInputRef = document.getElementById('reloadInputRef');
        if (reloadInputRef) {
            reloadInputRef.focus();
        } else {
            console.error('Element with ID reloadInputRef not found');
        }
    }


    return (
        <>
            <div className="container">
                <img src={process.env.PUBLIC_URL + '/logoWebsite.png'} alt="Logo" style={{ width: '150px' }} />
                <h1>Subnet Spectra</h1>
                <p style={{ maxWidth: '700px' }}>
                    Subnet Spectra is a user-friendly tool for network administrators, simplifying subnet calculations.
                    Quickly determine network IDs, subnet masks, broadcast addresses, and more, enhancing network planning
                    and management with accuracy and ease. Perfect for both learning and practical applications.
                </p>
                <div className="subContainer">
                    <div className="FormInputDiv">
                        <label>Network ID and CIDR notation (e.g., 192.168.1.0/24):</label>
                        <input
                            type="text"
                            value={ipAddress}
                            onChange={(e) => setIpAddress(e.target.value)}
                            placeholder="Enter Network ID and CIDR"
                            id="reloadInputRef"
                        />
                    </div>
                    <div className="FormInputDiv">
                        <label>How many subnetting groups do you need to enter?</label>
                        <input
                            type="number"
                            value={numGroups}
                            onChange={(e) => setNumGroups(parseInt(e.target.value))}
                            placeholder="Number of Groups"
                        />
                    </div>
                    <div className="FormBtnDiv">
                        <button id="addGroupBtn" onClick={() => addGroup(numGroups)}>Add Groups</button>
                        <button id="calculateBtn" onClick={removeAllGroups}>Remove All Groups</button>
                    </div>
                </div>
            </div>

            {/* Subnetting Groups Table */}
            <div className="containerTwo" id="containerTwo" ref={containerTwoRef}>
                <h2>Subnetting Groups</h2>
                <div className="flex-container">
                    {groupInfo.map((group, index) => (
                        <div key={index} className="flex-row">
                            <div className="flex-item">
                                <input
                                    type="text"
                                    value={group.name}
                                    onChange={(e) => handleGroupChange(index, "name", e.target.value)}
                                    placeholder="Group Name"
                                    className="tableInput"
                                />
                            </div>
                            <div className="flex-item">
                                <input
                                    type="number"
                                    value={group.hosts}
                                    onChange={(e) => handleGroupChange(index, "hosts", parseInt(e.target.value))}
                                    placeholder="Number of Hosts"
                                    className="tableInput"
                                />
                            </div>
                            <div className="flex-item">
                                <button id="removeBtn" onClick={() => removeGroup(index)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </div>
                {buttonVisible && (
                    <button id="calculateBtn" onClick={handleCalculate}>Calculate</button>
                )}
            </div>

            <div className="containerThree" id="containerThree" ref={containerThreeRef}>
                <div>
                    <h2 style={{ 'color': 'white' }}>Result</h2>
                    <div id="resultTable" className="containerTable"></div>
                </div>


            </div>

            {exportHTMLVisible && (<div className="containerFour">
                <h4>Export result to Excel</h4>
                <div className="subContainerFour">
                    <ExportToExcel data={data} fileName="SubnetSpectraSheet" />
                    <button onClick={clearAll} id="clearBtn">Clear</button>
                </div>
            </div>
            )}
        </>
    );
};

export default Home;
